import "./styles.css";
import React, { useState, useEffect, useMemo, useRef } from "react";
import { AnimatedCounter } from "react-animated-counter";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

import { ToggleButtonGroup, ToggleButton } from "@mui/material";

function convertTo24HourTime(unixTimestamp) {
  const date = new Date(parseInt(unixTimestamp, 10));
  const hours = (
    (date.getUTCHours() + 1).toString() == "24"
      ? "00"
      : (date.getUTCHours() + 1).toString()
  ).padStart(2, "0");
  const minutes = date.getUTCMinutes().toString().padStart(2, "0");
  return `${hours}:${minutes}`;
}

function parseData(data) {
  const result = data.reduce((acc, item) => {
    const time = convertTo24HourTime(item.time);

    acc.push({
      time,
      EdwardBoyle: item.EdwardBoyle < 0 ? 0 : item.EdwardBoyle,
      Laidlaw: item.Laidlaw < 0 ? 0 : item.Laidlaw,
    });

    return acc;
  }, []);

  return result;
}
const CustomTooltip = ({ payload, label }) => {
  return useMemo(
    () => (
      <div className="custom-tooltip">
        <p className="label">{label}</p>
        <div className="occupancy-row">
          <p className="inline-label" style={{ color: "#82ca9d" }}>
            Edward Boyle:
          </p>
          <div className="occupancy-container">
            <AnimatedCounter
              fontSize="18px"
              value={payload[0]?.value}
              decimalPrecision={0}
            />
          </div>
        </div>
        <div className="occupancy-row">
          <p className="inline-label" style={{ color: "#8884d8" }}>
            Laidlaw:
          </p>
          <div className="occupancy-container">
            <AnimatedCounter
              fontSize="18px"
              value={payload[1]?.value}
              decimalPrecision={0}
            />
          </div>
        </div>
      </div>
    ),
    [label, payload]
  );
};

const App = () => {
  const [occupancy, setOccupancy] = useState({});
  const [pastDay, setPastDay] = useState({});
  const [alignment, setAlignment] = useState("Today");
  const [cooldown, setCooldown] = useState(false);

  const alignmentRef = useRef(alignment);

  const handleAlignment = (event, newAlignment) => {
    if (cooldown || newAlignment == null) return;

    setAlignment(newAlignment);
    alignmentRef.current = newAlignment;

    setCooldown(true);

    setTimeout(() => {
      setCooldown(false);
    }, 5000);
  };

  const fetchData = () => {
    fetch("https://libapi.joshm.tech/current")
      .then((response) => response.json())
      .then((data) => {
        setOccupancy(data);
      });

    fetch(`https://libapi.joshm.tech/${alignmentRef.current.toLowerCase()}`)
      .then((response) => response.json())
      .then((data) => {
        const parsedData = parseData(data);
        setPastDay(parsedData);
      });
  };

  useEffect(() => {
    fetchData();

    const now = new Date();
    const delay = (60 - now.getSeconds()) * 1000;

    const initialTimeoutId = setTimeout(() => {
      fetchData();

      const intervalId = setInterval(fetchData, 60 * 1000);

      return () => clearInterval(intervalId);
    }, delay);

    return () => clearTimeout(initialTimeoutId);
  }, []);

  useEffect(() => {
    fetchData();
  }, [alignment]);

  return (
    <div className="App">
      <h1>Library Occupancy Statistics</h1>
      <div className="counter-container">
        <div className="counter-wrapper">
          <div className="counter-label">Edward Boyle</div>
          <AnimatedCounter
            decimalPrecision={0}
            value={
              occupancy["Edward Boyle"] < 0 ? 0 : occupancy["Edward Boyle"]
            }
            color="white"
            fontSize="40px"
          />
          <a href="https://library.leeds.ac.uk/locations/libraries/edward-boyle" target="_blank" style={{textDecoration: "none", color: "white"}}>Capacity: 1800</a>
        </div>
        <div className="counter-wrapper" style={{ padding: "10px 7px" }}>
          <div className="counter-label">Laidlaw</div>
          <AnimatedCounter
            decimalPrecision={0}
            value={occupancy["Laidlaw"] < 0 ? 0 : occupancy["Laidlaw"]}
            color="white"
            fontSize="40px"
          />
          <a href="https://library.leeds.ac.uk/locations/libraries/laidlaw" target="_blank" style={{textDecoration: "none", color: "white"}}>Capacity: 720</a>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            paddingLeft: "65px",
          }}
        >
          <ToggleButtonGroup
            color="primary"
            value={alignment}
            exclusive
            onChange={handleAlignment}
            aria-label="time"
          >
            <ToggleButton
              sx={{ color: cooldown ? "grey" : "white" }}
              value="Today"
            >
              Today
            </ToggleButton>
            <ToggleButton
              sx={{ color: cooldown ? "grey" : "white" }}
              value="Yesterday"
            >
              Yesterday
            </ToggleButton>
            <ToggleButton
              sx={{ color: cooldown ? "grey" : "white" }}
              value="Week"
            >
              Last Week
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
        <ResponsiveContainer height={300} width="95%">
          <LineChart width="100%" data={pastDay}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="time" tick={{ fill: "white" }} />
            <YAxis tick={{ fill: "white" }} domain={["auto", "auto"]} />
            <Tooltip content={<CustomTooltip />} />
            <Legend />

            <Line
              type="monotone"
              dataKey="EdwardBoyle"
              stroke="#82ca9d"
              name="Edward Boyle"
              dot={false}
            />
            <Line
              type="monotone"
              dataKey="Laidlaw"
              stroke="#8884d8"
              name="Laidlaw"
              dot={false}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default App;
